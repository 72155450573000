<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container mt-5 mb-5 pb-2" id="custom-cards">
      <h3 class="pb-2 border-bottom text-dark-blue">App & Url</h3>
      <!-- group inputs -->
      <div class="row col-md-12">
        <div class="col-3 text-start align-middle">
          <label for=""> Member</label>
          <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers" @select="SelectedMember" @clear="ClearMember" class="size-multiselect">
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div class="multiselect-tag is-user">
                <img :src="option.icon">
                <a :style="{'color':option.color}">{{ option.label }}</a>
                <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </div>
            </template>
            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon"> {{ option.label }}
            </template>
          </Multiselect>
        </div>
        <div class="col-3 text-start align-middle">
          <label for=""> Type</label>
          <Multiselect v-model="valueTypeSelect.value" v-bind="valueTypeSelect" @select="SelectedValueType" class="size-multiselect col-2"></Multiselect>
        </div>
        <div class="col-3 input-group-sm text-start align-middle">
          <label for=""> Date</label>
          <v-date-picker v-model="range" :model-config="modelConfig" is-range @dayclick="onDayClick">
            <template v-slot="{ inputValue, inputEvents }">
              <input class="form-control input-h bg-white input-group-lg border px-2 py-1 rounded" :value="inputValue.start" v-on="inputEvents.start" />
            </template>
          </v-date-picker>
        </div>
        <div id="selectapppro" class="col-3 text-start align-middle" style="visibility: visible;">
          <label for=""> Show by</label>
          <Multiselect v-model="typeSelect.value" v-bind="typeSelect" @select="SelectedType" class="size-multiselect col-2"></Multiselect>
        </div>
      </div>
      <!-- ./ group inputs -->
      <div class="text-end">
        <!--<div class="cursor-pointer d-inline m-3">
          <span data-bs-toggle="modal" data-bs-target="#send">Send Report <i class="fas fa-paper-plane"></i>
          </span>-->
          <!-- Modal -->
          <!--<div class="modal fade" id="send" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Send Report</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="text-start modal-body">
                  <form action="">
                    <div class="col-md-12">
                      <label for="validationDefault01" class="form-label">Email</label>-->
                      <!--<Multiselect/>-->
                    <!--</div>
                    <div class="col-md-12">
                      <label for="validationDefault02" class="form-label">Subject</label>
                      <input type="text" class="form-control" placeholder="Organization C.A. Time & Activity Report for Mon, Oct 11, 2021 - Sun, Oct 17, 2021" />
                    </div>
                    <div class="col-md-12">
                      <label for="validationDefault03" class="form-label">Message</label>
                      <textarea class="form-control">The Hispanos Soluciones C.A. Time & Activity Report for Mon, Oct 11, 2021 - Sun, Oct 17, 2021 is attached. Please download the attachment. Thank you.</textarea>
                    </div>
                    <div class="col-md-12">
                      <label for="validationDefault03" class="form-label">File Type</label>
                      <select class="form-select" aria-label="Default select example">
                        <option selected>PDF</option>
                        <option value="1">CSV</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div class="cursor-pointer d-inline m-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"> Export <i class="fas fa-download"></i>
        </div>
        <ul class="dropdown-menu">
          <div class="m-1">
            <li>
              <a class="dropdown-item" href="#" @click="valueExcelSelected">To EXCEL</a>
            </li>
            <!--<li>
              <a class="dropdown-item " href="#" @click="valuePdfSelected">To PDF</a>
            </li>-->
            <li>
              <a class="dropdown-item " href="#" @click="valueCsvSelected">To CSV</a>
            </li>
          </div>
        </ul>
      </div>
      <div class="m-2">
        <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mb-5 bg-body rounded">
          <div class="table-responsive">
            <table id="table-apps" class="table table-striped responsive" style="width:100%">
              <thead>
                <tr>
                  <th></th>
                  <th>{{nameTh}}</th>
                  <th>Time</th>
                  <th>Percent used (%)</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import moment from 'moment-timezone';
  import Multiselect from '@vueform/multiselect';
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import $ from 'jquery';
  import 'datatables.net-dt';

  export default 
  {
    name: 'AppUrl',
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      MultiSelectMembers: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions: true
      },
      typeSelect: 
      {
        value: ['Projects'],
        closeOnSelect: true,
        options: ['Projects', 'Apps'],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      valueTypeSelect: 
      {
        value: ['Apps'],
        closeOnSelect: true,
        options: ['Apps', 'Urls'],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      range: 
      {
        start: new Date(),
        end: new Date()
      },
      GetAppPost: 
      {
        date_init: new Date(),
        date_finish: new Date(),
        timezone: moment.tz.guess(true),
        id_session: ""
      },
      GetExportPost: 
      {
        date_init: new Date(),
        date_finish: new Date(),
        timezone: moment.tz.guess(true),
        download: 1,
        format: "excel",
        id_session: ""
      },
      CurrentDate: 
      {
        date: ""
      },
      ListAppsUrl: 
      {
        status: "false",
        list: {}
      },
      ListMembers: 
      {
        status: "false",
        list: {}
      },
      mostrar: false,
      optionSelected: 'Projects',
      optionValueSelected: 'Apps',
      nameTh: "Project"
    }),
    methods: 
    {
      ActualDate()
      {
        let date = "";
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let  month2 =  month>=10?month:'0'+ month;
        let day = new Date().getDate();
        date = year+"-"+month2+"-"+day;
        return date;
      },
      ValidateFields() 
      {
        if(this.MultiSelectMembers.value == "" || this.MultiSelectMembers.value == null  || this.typeSelect.value == null || this.valueTypeSelect.value == null || this.range == null)
        {
          return true
        }
      },
      GetMembers() 
      {
        Api.post("/member/list", "").then((result) => 
        {
          this.ListMembers.status = result.data.status

          if (this.ListMembers.status == "true") 
          {
            this.ListMembers.list = result.data.member

            for (var i = 0; i < this.ListMembers.list.length; i++) 
            {
              if (this.ListMembers.list[i].description == "Worker") 
              {
                var options = 
                {
                  value: this.ListMembers.list[i].id_session,
                  label: this.ListMembers.list[i].email,
                  icon: this.ListMembers.list[i].picture,
                  color: this.ListMembers.list[i].color
                }

                this.MultiSelectMembers.options.push(options)
              }
            }
          }
        })
      },
      SelectedMember(option) 
      {
        this.myid = option;
        //Test Blocks
        this.GetAppPost.id_session = this.myid;
        this.GetExportPost.id_session = this.myid;

        if(this.ValidateFields() != true)
        {
          this.GetAppsUrl(1);
        }
        /*Test Blocks
        this.MultiSelectProject.options = [];
        this.$refs.multiselect.clear();
        this.GetMembersProject();*/
      },
      ClearMember(option) 
      {
        /*this.$refs.multiselect.clear();
        this.MultiSelectProject.options = [];*/
      },
      GetAppsUrl(flag) 
      {
        let typeUrl = "";

        if(this.optionValueSelected == 'Apps') 
        {
          //$("#selectapppro").css({'visibility':'visible'});

          if (this.optionSelected == 'Projects') 
          {
            typeUrl = "/reports/projects/apps";
            this.nameTh = "Project";
          } 
          else 
          {
            typeUrl = "/reports/apps";
            this.nameTh = "App";
          }
        }
        else 
        {
          //$("#selectapppro").css({'visibility':'hidden'});
          typeUrl = "/reports/urls";
          this.nameTh = "Domain";
        }

        Api.post(typeUrl, this.GetAppPost).then((result) => 
        {
          //console.log(result);
          this.ListAppsUrl.status = result.data.status

          if (this.ListAppsUrl.status == "true") 
          {
            this.ListAppsUrl.list = result.data.list;

            if (flag == 0) 
            {
              this.InitDatatable();
            } 
            else 
            {
              this.ChangeDataTable();
            }
          }
        })
      },
      GetCurrentDate() 
      {
        var currentDateWithFormat = this.ActualDate();//new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        this.GetAppPost.date = currentDateWithFormat
      },
      valueExcelSelected() 
      {
        this.GetExportPost.format = "xlsx";
        this.sendExport();
      },
      valuePdfSelected() 
      {
        this.GetExportPost.format = "pdf";
        this.sendExport();
      },
      valueCsvSelected() 
      {
        this.GetExportPost.format = "csv";
        this.sendExport();
      },
      sendExport() 
      {
        let exportUrl = "";

        if(this.optionValueSelected == 'Apps') 
        {
          if (this.optionSelected == 'Projects') 
          {
            exportUrl = "/reports/projects/apps";
          } 
          else 
          {
            exportUrl = "/reports/apps";
          }
        } 
        else 
        {
          exportUrl = "reports/projects/urls";
        }

        Api.post(exportUrl, this.GetExportPost).then((result) => 
        {
          if (result.data.status == "true") 
          {
            /*if(result.data.link != undefined) {
              var win = window.open(result.data.link, '_blank');
            }*/
            var nameUrl = result.data.name;
            var fileLink = document.createElement('a');
            fileLink.href = result.data.link;
            document.body.appendChild(fileLink);
            fileLink.setAttribute('download', nameUrl);
            fileLink.click();
          }
        })
      },
      SelectedType(option) 
      {
        this.optionSelected = option;
        if(this.ValidateFields() != true)
        {
          this.GetAppsUrl(1);
        }
      },
      SelectedValueType(option) 
      {
        this.optionValueSelected = option;

        if(this.optionValueSelected == 'Apps') 
        {
          $("#selectapppro").css({'visibility':'visible'});
        }
        else 
        {
          $("#selectapppro").css({'visibility':'hidden'});
        }

        if(this.ValidateFields() != true)
        {
          this.GetAppsUrl(1);
        }
      },
      onDayClick(day) 
      {
        this.GetAppPost.date_init = this.range.start;
        this.GetAppPost.date_finish = this.range.end;
        this.GetExportPost.date_init = this.range.start;
        this.GetExportPost.date_finish = this.range.end;
        if(this.ValidateFields() != true)
        {
          this.GetAppsUrl(1);
        }
      },
      ChangeDataTable() 
      {
        var newData = this.ListAppsUrl.list;
        var table = $('#table-apps').DataTable();
        table.clear().rows.add(newData).draw();
      },
      InitDatatable() 
      {
        let mydata = this.ListAppsUrl.list;
        /* Formatting function for row details - modify as you need */
        function format(d) 
        {
          let getData = d.type;
          let trtable = "";

          for (var i = 0; i < getData.length; i++) 
          {
            trtable += '<tr>'+
            '<td>'+getData[i].name+'</td>'+
            '<td>'+getData[i].time+'</td>'+
            '<td>'+getData[i].percentage+'%</td>'+
            '</tr>';
          }

          // `d` is the original data object for the row
          return '<table cellpadding = "5" cellspacing = "0" border = "0" style = "width:100%;">'+
          '<thead>'+
          '<tr>'+
          '<th>Name</th>'+
          '<th>Time</th>'+
          '<th>Percent used</th>'+
          '</tr>'+
          '</thead>'+
          trtable + 
          '</table>';
        }

        var table = $('#table-apps').DataTable(
        {
          "data": this.ListAppsUrl.list,
          "columns": [
          {
            "className": 'dt-control',
            "orderable": false,
            "data": null,
            "defaultContent": ''
          },
          {
            "data": "name"
          },
          {
            "data": "time"
          },
          {
            "data": "percentage"
          }],
          /*"order": [
            [1, 'asc']
          ]*/
        });

        // Add event listener for opening and closing details
        $('#table-apps tbody').on('click', 'td.dt-control', function() 
        {
          var tr = $(this).closest('tr');
          var row = table.row(tr);

          if (row.child.isShown()) 
          {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
          } 
          else 
          {
            // Open this row
            row.child(format(row.data())).show();
            tr.addClass('shown');
          }
        });
      }
    },
    mounted() 
    {
      this.GetCurrentDate(),
      this.GetAppsUrl(0),
      this.GetMembers()
    }
  }
</script>

<style>
/* estilos para el multiselect */
.multiselect {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
    border-radius: var(--ms-radius, 4px);
    background: var(--ms-bg, #fff);
    font-size: var(--ms-font-size, 1rem);
    min-height: calc(var(--ms-border-width,  1px)*2 + var(--ms-font-size,  1rem)*var(--ms-line-height,  1.375) + var(--ms-py,  .5rem)*2);
}
.multiselect.is-open {
    border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) 0 0;
}
.multiselect.is-open-top {
    border-radius: 0 0 var(--ms-radius, 4px) var(--ms-radius, 4px);
}
.multiselect.is-disabled {
    cursor: default;
    background: var(--ms-bg-disabled, #f3f4f6);
}
.multiselect.is-active {
    box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, rgba(16, 61, 185, 0.188));
}
.multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    background: transparent;
    line-height: var(--ms-line-height, 1.375);
    padding-left: var(--ms-px, .875rem);
}
.multiselect-placeholder {
    color: var(--ms-placeholder-color, #9ca3af);
}
.multiselect-search {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: none;
    box-sizing: border-box;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: inherit;
    font-family: inherit;
    background: var(--ms-bg, #fff);
    border-radius: var(--ms-radius, 4px);
    padding-left: var(--ms-px, .875rem);
}
.multiselect-search::-webkit-search-cancel-button, .multiselect-search::-webkit-search-decoration, .multiselect-search::-webkit-search-results-button, .multiselect-search::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
.multiselect-tags {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    margin: var(--ms-tag-my, .25rem) 0 0;
    padding-left: var(--ms-py, .5rem);
    align-items: center;
}
.multiselect-tag {
    background: var(--ms-tag-bg, #0D3E67);
    color: var(--ms-tag-color, #fff);
    font-size: var(--ms-tag-font-size, .875rem);
    line-height: var(--ms-tag-line-height, 1.25rem);
    font-weight: var(--ms-tag-font-weight, 600);
    padding: var(--ms-tag-py, .125rem) 0 var(--ms-tag-py, .125rem) var(--ms-tag-px, .5rem);
    border-radius: var(--ms-tag-radius, 4px);
    margin-right: var(--ms-tag-mx, .25rem);
    margin-bottom: var(--ms-tag-my, .25rem);
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.multiselect-tag.is-disabled {
    padding-right: var(--ms-tag-px, .5rem);
    background: var(--ms-tag-bg-disabled, #9ca3af);
    color: var(--ms-tag-color-disabled, #fff);
}
.multiselect-tag-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--ms-tag-remove-py, .25rem) var(--ms-tag-remove-px, .25rem);
    margin: var(--ms-tag-remove-my, 0) var(--ms-tag-remove-mx, .125rem);
    border-radius: var(--ms-tag-remove-radius, 4px);
}
.multiselect-tag-remove:hover {
    background: rgba(0, 0, 0, .06274509803921569);
}
.multiselect-tag-remove-icon {
   -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
     -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: currentColor;
    opacity: .8;
    display: inline-block;
    width: .75rem;
    height: .75rem;
}
.multiselect-tags-search-wrapper {
    display: inline-block;
    position: relative;
    margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%}
.multiselect-tags-search-copy {
    visibility: hidden;
    white-space: pre-wrap;
    display: inline-block;
    height: 1px;
    width: 100%}
.multiselect-tags-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    box-sizing: border-box;
    width: 100%;
    appearance: none;
}
.multiselect-tags-search::-webkit-search-cancel-button, .multiselect-tags-search::-webkit-search-decoration, .multiselect-tags-search::-webkit-search-results-button, .multiselect-tags-search::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
.multiselect-spinner {
    -webkit-mask-image: url("data:image/svg+xml;charset=utf-8, %3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http: //www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8, %3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http: //www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: var(--ms-spinner-color, #0D3E67);
    width: 1rem;
    height: 1rem;
    z-index: 10;
    margin: 0 var(--ms-px, .875rem) 0 0;
    -webkit-animation: multiselect-spin 1s linear infinite;
    animation: multiselect-spin 1s linear infinite;
    flex-shrink: 0;
    flex-grow: 0;
}
.multiselect-clear {
    padding: 0 var(--ms-px, .875rem) 0 0;
    position: relative;
    z-index: 10;
    opacity: 1;
    transition: .3s;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
}
.multiselect-clear:hover .multiselect-clear-icon {
    background-color: var(--ms-clear-color-hover, #000);
}
.multiselect-clear-icon {
    -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    background-color: var(--ms-clear-color,#999);
    display: inline-block;
    transition: .3s;  
}
.multiselect-caret, .multiselect-clear-icon {
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: .625rem;
    height: 1.125rem;
}
.multiselect-caret {
    transform: rotate(0deg);
    transition: transform .3s;
    -webkit-mask-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"); 
    mask-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-color: var(--ms-caret-color,#999);
    margin: 0 var(--ms-px,.875rem) 0 0;
    position: relative;
    z-index: 10;
    flex-shrink: 0;
    flex-grow: 0;
    pointer-events: none;
}
.multiselect-caret.is-open {
    transform: rotate(180deg);
    pointer-events: auto;
}
.multiselect-dropdown {
    position: absolute;
    left: calc(var(--ms-border-width,  1px)*-1);
    right: calc(var(--ms-border-width,  1px)*-1);
    bottom: 0;
    transform: translateY(100%);
    border: var(--ms-dropdown-border-width, 1px) solid var(--ms-dropdown-border-color, #d1d5db);
    margin-top: calc(var(--ms-border-width,  1px)*-1);
    max-height: 15rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 100;
    background: var(--ms-dropdown-bg, #fff);
    display: flex;
    flex-direction: column;
    border-radius: 0 0 var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px);
    outline: none;
}
.multiselect-dropdown.is-top {
    transform: translateY(-100%);
    top: var(--ms-border-width, 1px);
    bottom: auto;
    flex-direction: column-reverse;
    border-radius: var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px) 0 0;
}
.multiselect-dropdown.is-hidden {
    display: none;
}
.multiselect-options {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    max-height: var(--ms-max-height, 10rem);
}
.multiselect-options.is-top {
    flex-direction: column-reverse;
}
.multiselect-group {
    padding: 0;
    margin: 0;
    
}
.multiselect-group-label {
    padding: var(--ms-group-label-py, .3rem) var(--ms-group-label-px, .75rem);
    font-size: .875rem;
    font-weight: 600;
    background: #e5e7eb;
    color: #374151;
    cursor: default;
    line-height: var(--ms-group-label-line-height, 1.375);
    display: flex;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}
.multiselect-group-label.is-pointable {
    cursor: pointer;
}
.multiselect-group-label.is-pointed {
    background: var(--ms-group-label-bg-pointed, #d1d5db);
    color: var(--ms-group-label-color-pointed, #374151);
}
.multiselect-group-label.is-selected {
    background: var(--ms-group-label-bg-selected, #0D3E67);
    color: var(--ms-group-label-color-selected, #fff);
}
.multiselect-group-label.is-disabled {
    background: var(--ms-group-label-bg-disabled, #f3f4f6);
    color: var(--ms-group-label-color-disabled, #d1d5db);
    cursor: not-allowed;
}
.multiselect-group-label.is-selected.is-pointed {
    background: var(--ms-group-label-bg-selected-pointed, #0D3E67);
    color: var(--ms-group-label-color-selected-pointed, #fff);
}
.multiselect-group-label.is-selected.is-disabled {
    background: var(--ms-group-label-bg-selected-disabled, #0D3E67);
    color: var(--ms-group-label-color-selected-disabled, #d1fae5);
}
.multiselect-group-options {
    padding: 0;
    margin: 0;
}
.multiselect-option {
    padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
    font-size: var(--ms-option-font-size, 1rem);
    line-height: var(--ms-option-line-height, 1.375);
    cursor: pointer;
    display: flex;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}
.multiselect-option.is-pointed {
    background: var(--ms-option-bg-pointed, #f3f4f6);
    color: var(--ms-option-color-pointed, #1f2937);
}
.multiselect-option.is-selected {
    background: var(--ms-option-bg-selected, #0D3E67);
    color: var(--ms-option-color-selected, #fff);
}
.multiselect-option.is-disabled {
    background: var(--ms-option-bg-disabled, #fff);
    color: var(--ms-option-color-disabled, #d1d5db);
    cursor: not-allowed;
}
.multiselect-option.is-selected.is-pointed {
    background: var(--ms-option-bg-selected-pointed, #0D3E67);
    color: var(--ms-option-color-selected-pointed, #fff);
}
.multiselect-option.is-selected.is-disabled {
    background: var(--ms-option-bg-selected-disabled, #87dcc0);
    color: var(--ms-option-color-selected-disabled, #d1fae5);
}
.multiselect-no-options, .multiselect-no-results {
    padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
    color: var(--ms-empty-color, #4b5563);
}
.multiselect-fake-input {
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    border: 0;
    padding: 0;
    font-size: 0;
    outline: none;
}
.multiselect-fake-input:active, .multiselect-fake-input:focus {
    outline: none;
}
.multiselect-spacer {
    display: none;
}
@-webkit-keyframes multiselect-spin {
    0% {
    transform: rotate(0);
}
to {
    transform: rotate(1turn);
}
}@keyframes multiselect-spin {
    0% {
    transform: rotate(0);
}
to {
    transform: rotate(1turn);
}
}

/* end */ 
.text-orange{
    color:#EA7826;
}
.cursor-pointer{
    cursor: pointer;
}
</style>